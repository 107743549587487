import type { WorkflowSharedTypes } from '@openphone/dexie-database/types'
import { action, makeAutoObservable, observable, runInAction } from 'mobx'

import type { StepDefinitionId } from '@src/app/components/workflow/builder/WorkflowBuilderController'
import RingOrderFormSchema from '@src/app/settings/phone-number/call-flow/RingOrder/RingOrderFormSchema'
import { DisposeBag } from '@src/lib/dispose'
import isNonNull from '@src/lib/isNonNull'
import uuid from '@src/lib/uuid'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { EntityPhoneNumberModel } from './model'
import type { RawWorkflowDefinition } from './model/workflow/WorkflowDefinitionModel'
import WorkflowDefinitionModel from './model/workflow/WorkflowDefinitionModel'
import type { RawWorkflowStepDefinition } from './model/workflow/WorkflowStepDefinitionModel'
import WorkflowStepDefinitionModel from './model/workflow/WorkflowStepDefinitionModel'
import type { RawWorkflowTriggerDefinition } from './model/workflow/WorkflowTriggerDefinitionModel'
import WorkflowTriggerDefinitionModel from './model/workflow/WorkflowTriggerDefinitionModel'
import makePersistable from './storage/makePersistable'
import type { Validation } from './transport/WorkflowClient'
import {
  WORKFLOW_DEFINITION_TABLE_NAME,
  type WorkflowDefinitionRepository,
} from './worker/repository/WorkflowDefinitionRepository'
import type { WorkflowStepDefinitionRepository } from './worker/repository/WorkflowStepDefinitionRepository'
import { WORKFLOW_STEP_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowStepDefinitionRepository'
import type { WorkflowTriggerDefinitionRepository } from './worker/repository/WorkflowTriggerDefinitionRepository'
import { WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowTriggerDefinitionRepository'

export const generateStepId = () => `WS${uuid()}`.replace(/-/g, '')

export const isPhoneMenuConfigValue = (
  value: WorkflowSharedTypes.WorkflowConfigurationValue['value'] | null | undefined,
): value is WorkflowSharedTypes.PhoneMenuConfigValue => {
  if (value && typeof value === 'object' && value['variableKey'] === undefined) {
    return true
  }

  return false
}

const isPhoneMenuDigit = (
  value: number | string,
): value is WorkflowSharedTypes.PhoneMenuDigit => {
  return Boolean(
    value === 'no-selection' ||
      value === 0 ||
      value === 1 ||
      value === 2 ||
      value === 3 ||
      value === 4 ||
      value === 5 ||
      value === 6 ||
      value === 7 ||
      value === 8 ||
      value === 9,
  )
}

const getPhoneMenuDestinationByNodeData = (
  stepDefinitionId: StepDefinitionId,
  configuration: WorkflowSharedTypes.WorkflowConfigurationValue[],
  phoneNumberId: string,
): string => {
  let value: WorkflowSharedTypes.WorkflowConfigurationValue['value'] | undefined =
    undefined

  if (stepDefinitionId === 'WSDgoTo') {
    value = 'repeat'
  }

  if (stepDefinitionId === 'WSDforwardCall') {
    value = configuration.find((entry) => entry.variableKey === 'WVforwardeePhoneNumber')
      ?.value
  }

  if (stepDefinitionId === 'WSDvoicemail') {
    value = configuration.find((entry) => entry.variableKey === 'WVvoicemailUrl')?.value
  }

  if (stepDefinitionId === 'WSDringUsers') {
    value =
      configuration.find((entry) => entry.variableKey === 'WVspecificUserToDial')
        ?.value || phoneNumberId
  }

  if (stepDefinitionId === 'WSDplayAudio') {
    value = configuration.find((entry) => entry.variableKey === 'WVaudioFileUrl')?.value
  }

  if (stepDefinitionId === 'WSDplayAudioAndContinue') {
    value = configuration.find((entry) => entry.variableKey === 'WVaudioFileUrl')?.value
  }

  if (typeof value !== 'string' || !value) {
    return ''
  }

  return value
}

export default class WorkflowStore {
  private readonly definitionsCollection: PersistedCollection<
    WorkflowDefinitionModel,
    WorkflowDefinitionRepository
  >
  private readonly stepDefinitionsCollection: PersistedCollection<
    WorkflowStepDefinitionModel,
    WorkflowStepDefinitionRepository
  >
  private readonly triggerDefinitionsCollection: PersistedCollection<
    WorkflowTriggerDefinitionModel,
    WorkflowTriggerDefinitionRepository
  >
  // We keep track of the entity ids for which we have fetched definitions
  private readonly fetchedDefinitionsEntityIds = new Set<string>()
  private readonly fetchedStepDefinitionIds = new Set<string>()
  private readonly fetchedTriggerDefinitionIds = new Set<string>()
  // Map of definitions that have been modified since they were fetched from the server.
  // This map stores the raw definition before it was modified.
  private readonly definitionsBeforeModificationsById = new Map<
    string,
    RawWorkflowDefinition
  >()
  private readonly historyById = new Map<
    string,
    {
      currentIndex: number | null
      history: RawWorkflowDefinition[]
    }
  >()

  private readonly disposeBag = new DisposeBag()

  constructor(private root: Service) {
    this.definitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowDefinition) =>
        new WorkflowDefinitionModel(json),
    })
    this.stepDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_STEP_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowStepDefinition) =>
        new WorkflowStepDefinitionModel(json),
    })
    this.triggerDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowTriggerDefinition) =>
        new WorkflowTriggerDefinitionModel(json),
    })

    makeAutoObservable<
      this,
      | 'definitionsCollection'
      | 'stepDefinitionsCollection'
      | 'triggerDefinitionsCollection'
    >(this, {
      definitionsCollection: observable,
      stepDefinitionsCollection: observable,
      triggerDefinitionsCollection: observable,
    })

    makePersistable(this, 'WorkflowStore', {
      definitionsBeforeModificationsById: root.storage.async(),
      historyById: root.storage.async(),
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    this.load()
  }

  beta = {
    optIn: async (phoneNumbers: EntityPhoneNumberModel[]) => {
      await this.root.transport.voice.workflow.optIn(
        phoneNumbers.map((phoneNumber) => phoneNumber.id),
      )
      phoneNumbers.forEach(
        action((phoneNumber) => {
          phoneNumber.settings.incomingCallWorkflowEnabled = true
          this.root.organization.phoneNumber.update(phoneNumber)
        }),
      )
    },

    optOut: async (phoneNumbers: EntityPhoneNumberModel[]) => {
      await this.root.transport.voice.workflow.optOut(
        phoneNumbers.map((phoneNumber) => phoneNumber.id),
      )
      phoneNumbers.forEach(
        action((phoneNumber) => {
          phoneNumber.settings.incomingCallWorkflowEnabled = false
          this.root.organization.phoneNumber.update(phoneNumber)
        }),
      )
    },
  }

  getDefinitionById(definitionId: string): WorkflowDefinitionModel | null {
    return this.definitionsCollection.get(definitionId)
  }

  getWorkflowStepById(
    workflowDefinitionId: string,
    stepId: string,
  ): WorkflowDefinitionModel['workflowSteps'][string] | null {
    const definition = this.getDefinitionById(workflowDefinitionId)

    const workflowStep = definition?.workflowSteps[stepId]

    return workflowStep ?? null
  }

  private getDefinitionsForEntityIds(entityIds: string[]): WorkflowDefinitionModel[] {
    const localDefinitionsForEntityIds = this.definitionsCollection.list.filter(
      (definition) => entityIds.includes(definition.entityId),
    )

    this.fetchDefinitionsForEntityIdsIfNeeded(entityIds, localDefinitionsForEntityIds)

    return localDefinitionsForEntityIds
  }

  getDefinitionsForEntityId(entityId: string): WorkflowDefinitionModel[] {
    return this.getDefinitionsForEntityIds([entityId])
  }

  getEnabledDefinitionForEntityId(entityId: string): WorkflowDefinitionModel | null {
    return (
      this.getDefinitionsForEntityId(entityId).find((definition) => definition.enabled) ??
      null
    )
  }

  getStepDefinitionById(stepDefinitionId: string): WorkflowStepDefinitionModel | null {
    const localStepDefinition = this.stepDefinitionsCollection.get(stepDefinitionId)

    // TODO disabling this for now since it was called too frequently
    // this.fetchStepDefinitionFromRemoteIfNeeded(stepDefinitionId, localStepDefinition)

    return localStepDefinition ?? null
  }

  getTriggerDefinitionById(
    triggerDefinitionId: string,
  ): WorkflowTriggerDefinitionModel | null {
    const localTriggerDefinition =
      this.triggerDefinitionsCollection.get(triggerDefinitionId)

    // TODO disabling this for now since it was called too frequently
    // this.fetchTriggerDefinitionFromRemoteIfNeeded(
    //   triggerDefinitionId,
    //   localTriggerDefinition,
    // )

    return localTriggerDefinition ?? null
  }

  private updateLocalWorkflow(
    definition: WorkflowDefinitionModel,
    skipAddingToHistory = false,
  ) {
    if (!this.definitionsBeforeModificationsById.has(definition.id)) {
      const rawOriginalDefinition = this.definitionsCollection
        .get(definition.id)
        ?.serialize()

      if (!rawOriginalDefinition) {
        return
      }

      this.definitionsBeforeModificationsById.set(
        rawOriginalDefinition.id,
        rawOriginalDefinition,
      )
    }

    const augmentedDefinition = this.augmentDefinitionConfiguration(definition, false)

    this.definitionsCollection.put(augmentedDefinition)

    if (skipAddingToHistory) {
      return
    }

    this.addDefinitionToHistory(augmentedDefinition)
  }

  addDefinitionToHistory(definition: WorkflowDefinitionModel) {
    const currentHistory = this.historyById.get(definition.id)
    const rawOriginalDefinition = this.definitionsBeforeModificationsById.get(
      definition.id,
    )

    if (!rawOriginalDefinition) {
      return
    }

    this.historyById.set(
      definition.id,
      currentHistory
        ? {
            currentIndex: null,
            history: [
              ...currentHistory.history.slice(
                0,
                currentHistory.currentIndex ? currentHistory.currentIndex + 1 : undefined,
              ),
              definition.serialize(),
            ],
          }
        : {
            currentIndex: null,
            history: [rawOriginalDefinition, definition.serialize()],
          },
    )
  }

  undo(definitionId: string) {
    const currentHistory = this.historyById.get(definitionId)

    if (!currentHistory) {
      return
    }

    const newIndex =
      (currentHistory.currentIndex ?? currentHistory.history.length - 1) - 1

    if (newIndex < 0) {
      return
    }

    const definitionToRestore = new WorkflowDefinitionModel(
      currentHistory.history[newIndex],
    )

    this.updateLocalWorkflow(definitionToRestore, true)

    this.historyById.set(definitionId, {
      ...currentHistory,
      currentIndex: newIndex,
    })
  }

  redo(definitionId: string) {
    const currentHistory = this.historyById.get(definitionId)

    if (!currentHistory) {
      return
    }

    if (currentHistory.currentIndex === null) {
      return
    }

    const newIndex = currentHistory.currentIndex + 1

    if (newIndex > currentHistory.history.length - 1) {
      return
    }

    const definitionToRestore = new WorkflowDefinitionModel(
      currentHistory.history[newIndex],
    )

    this.updateLocalWorkflow(definitionToRestore, true)

    this.historyById.set(definitionId, {
      ...currentHistory,
      currentIndex: newIndex,
    })
  }

  private augmentDefinitionConfiguration(
    definition: WorkflowDefinitionModel,
    preventEmptyPhoneMenuDestinations,
  ) {
    const localDefinition = definition.serialize()

    Object.entries(localDefinition.workflowSteps).forEach(([stepId, step]) => {
      if (step.definitionId !== 'WSDphoneMenu') {
        return
      }

      const builtValue: WorkflowSharedTypes.PhoneMenuConfigValue = {}
      const value = step.configuration?.find(
        (config) => config.variableKey === 'WVphoneMenuOptions',
      )?.value

      step.branches?.forEach((branch) => {
        const isDefaultOption = branch.key === 'WBdefaultOption'
        const phoneMenuConfigKey = isDefaultOption
          ? 'optionDefault'
          : (branch.key.replace(
              'WB',
              '',
            ) as keyof WorkflowSharedTypes.PhoneMenuConfigValue)

        const target = localDefinition.workflowSteps[branch.nextStepId]

        if (!target) {
          return
        }

        const currentValue = isPhoneMenuConfigValue(value) ? value : {}

        const digit = isDefaultOption
          ? 'no-selection'
          : Number(branch.key.replace('WBoption', ''))

        if (!isPhoneMenuDigit(digit)) {
          return
        }

        const destination = getPhoneMenuDestinationByNodeData(
          target?.definitionId,
          target.configuration ?? [],
          localDefinition.entityId,
        )

        if (!destination && preventEmptyPhoneMenuDestinations) {
          return
        }

        builtValue[phoneMenuConfigKey] = {
          digit,
          name: currentValue[phoneMenuConfigKey]?.name ?? undefined,
          phrase: currentValue[phoneMenuConfigKey]?.phrase ?? undefined,
          destination,
        }
      })

      step.configuration = [
        ...(step.configuration?.filter(
          (c) =>
            c.variableKey !== 'WVphoneMenuOptions' &&
            c.variableKey !== 'WVphoneMenuDefaultDestination',
        ) ?? []),
        {
          variableKey: 'WVphoneMenuOptions',
          value: builtValue,
        },
        {
          variableKey: 'WVphoneMenuDefaultDestination',
          value: builtValue.optionDefault?.destination ?? '',
        },
      ]

      // sort phone menu steps by key
      localDefinition.workflowSteps[stepId] = {
        ...step,
        branches: step.branches?.sort((a, b) => {
          // Sort branches by key so that the phone menu branches are always ordered by digit (since the digit is part of the key).
          // The default option should always be last.
          if (a.key === 'WBdefaultOption') {
            return 1
          }

          if (b.key === 'WBdefaultOption') {
            return -1
          }

          return a.key < b.key ? -1 : 1
        }),
      }
    })

    return new WorkflowDefinitionModel(localDefinition)
  }

  enableIncomingCallWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)

    // Do nothing if the definition is not found, if it's already enabled,
    // or if it has pending changes
    if (!definition || definition.enabled || this.hasChanges(definitionId)) {
      return
    }

    const phoneNumber = this.root.organization.phoneNumber.collection.get(
      definition.entityId,
    )

    // Unlikely to happen, but guard against no phone number
    if (!phoneNumber) {
      return
    }

    const initialPhoneNumberForward = phoneNumber.forward

    // Get the currently enabled definition so we can optimisitcally disable it
    const enabledDefinition = this.getEnabledDefinitionForEntityId(definition.entityId)

    try {
      // If enabling the Forward All Calls workflow, update the phone number forward
      // setting to keep the mobile clients in sync
      if (definition.isForwardAllCallsIncomingCallFlow) {
        const forwardStep = Object.values(definition.workflowSteps).find(
          (step) => step.definitionId === 'WSDforwardCall',
        )

        const forwardConfigValue = forwardStep?.configuration?.find(
          (config) => config.variableKey === 'WVforwardeePhoneNumber',
        )?.value

        if (typeof forwardConfigValue === 'string') {
          phoneNumber.update({ forward: forwardConfigValue })
        }
      } else if (phoneNumber.forward !== null) {
        // If enabling a definition other than the Forward All Calls workflow, clear
        // the phone number forward setting to keep the mobile clients in sync, but
        // only if it's not already null to prevent an unnecessary update
        phoneNumber.update({ forward: null })
      }

      definition.deserialize({ ...definition.serialize(), enabled: true })
      enabledDefinition?.deserialize({
        ...enabledDefinition.serialize(),
        enabled: false,
      })

      // The web client simply needs to make this call to enable a workflow definition
      // and the backend will ensure that only one incoming call workflow is enabled
      // at a time for a given entityId (i.e. phoneNumberId).
      return this.root.transport.voice.workflow.enableDefinition(
        definition.entityId,
        definitionId,
      )
    } catch (error) {
      // Revert the changes to both the definitions if something goes wrong.
      // If in the odd chance an error occured but the definitions were still
      // updated on the backend, the client will receive websocket events with
      // the definition updates and the data will correct itself at that point.
      definition.deserialize({ ...definition.serialize(), enabled: false })
      enabledDefinition?.deserialize({
        ...enabledDefinition.serialize(),
        enabled: true,
      })

      // Ensure the phone number forward setting is reverted
      phoneNumber.update({ forward: initialPhoneNumberForward })

      throw error
    }
  }

  async publishForwardAllCallsWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)
    // We need to store the definitionBeforeModifications object otherwise it will be overwritten by the
    // websocket 'workflow-definition-update' message
    const definitionBeforeModifications =
      this.definitionsBeforeModificationsById.get(definitionId)

    if (!definition || !definitionBeforeModifications) {
      return
    }

    const phoneNumber = this.root.organization.phoneNumber.collection.get(
      definition.entityId,
    )
    const forwardStep = Object.values(definition.workflowSteps).find(
      (step) => step.definitionId === 'WSDforwardCall',
    )
    const forwardConfigValue = forwardStep?.configuration?.find(
      (config) => config.variableKey === 'WVforwardeePhoneNumber',
    )?.value

    if (!phoneNumber || typeof forwardConfigValue !== 'string') {
      return
    }

    const initialPhoneNumberForward = phoneNumber.forward

    // Clear the definitionsBeforeModificationsById entry so that the banner disappears
    this.definitionsBeforeModificationsById.delete(definitionId)

    try {
      const validation = await this.validateWorkflow(definitionId)

      if (!validation.valid) {
        throw validation
      }

      // If the definition is enabled, update the phone number forward setting to keep
      // the mobile clients in sync
      if (definition.enabled) {
        phoneNumber.update({ forward: forwardConfigValue })
      }

      const response = await this.root.transport.workflow.definitions.put(
        definition.id,
        definition.serialize(),
      )

      // then store the updated definition in the collection (not necessary since it's already stored, but just in case)
      this.definitionsCollection.put(new WorkflowDefinitionModel(response))
    } catch (error) {
      // if there's an error, store the definitionBeforeModifications object back in the map
      this.definitionsBeforeModificationsById.set(
        definitionId,
        definitionBeforeModifications,
      )
      // and make sure that the phone number forward setting is reverted too, if it was changed
      if (definition.enabled) {
        phoneNumber.update({ forward: initialPhoneNumberForward })
      }

      throw error
    }
  }

  async publishWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)
    // We need to store the definitionBeforeModifications object otherwise it will be overwritten by the
    // websocket 'workflow-definition-update' message
    const definitionBeforeModifications =
      this.definitionsBeforeModificationsById.get(definitionId)

    if (!definition || !definitionBeforeModifications) {
      return
    }

    // Clear the definitionsBeforeModificationsById entry so that the banner disappears
    this.definitionsBeforeModificationsById.delete(definitionId)

    try {
      const validation = await this.validateWorkflow(definitionId)

      if (!validation.valid) {
        throw validation
      }

      // first create or update any ring orders in this definition
      await this.updateOrCreateRingOrders(definition)

      // then update the definition
      const response = await this.root.transport.workflow.definitions.put(
        definition.id,
        definition.serialize(),
      )

      // then store the updated definition in the collection (not necessary since it's already stored, but just in case)
      this.definitionsCollection.put(new WorkflowDefinitionModel(response))

      // then once all of that succeeds, start a non-blocking request to clean up the
      // deleted ring orders from the remote
      this.deleteRemovedRingOrdersFromRemote(definition, definitionBeforeModifications)
    } catch (error) {
      // if there's an error, store the definitionBeforeModifications object back in the map
      this.definitionsBeforeModificationsById.set(
        definitionId,
        definitionBeforeModifications,
      )

      throw error
    }
  }

  async validateWorkflow(definitionId: string): Promise<Validation> {
    const definition = this.definitionsCollection.get(definitionId)

    if (!definition) {
      // This should never happen, but let's throw a generic validation error if it does
      return {
        valid: false,
        errors: [
          {
            path: '',
            message: 'Definition not found',
          },
        ],
      }
    }

    // Validate the workfow against the backend. This way, workflowValidation is initialized to a
    // Validation object.
    const workflowValidation = await this.root.transport.workflow.definitions.validate(
      definitionId,
      this.augmentDefinitionConfiguration(definition, true).serialize(),
    )

    // Get all the ringOrderIds that need to be validated from the workflow steps of the definition
    const ringOrderIdsToValidate = Object.values(definition.workflowSteps)
      .map((step) => {
        if (step.definitionId !== 'WSDringUsers') {
          return null
        }

        const specificUserToDial = step.configuration?.find(
          (c) => c.variableKey === 'WVspecificUserToDial',
        )?.value

        // If the step is a ringUsers step with a specificUserToDial configuration, we don't need to validate the ring order
        // since we'll ignore it
        if (specificUserToDial) {
          return null
        }

        const ringOrderId = step.configuration?.find(
          (c) => c.variableKey === 'WVringOrderId',
        )?.value

        if (typeof ringOrderId !== 'string') {
          return null
        }

        return ringOrderId
      })
      .filter(isNonNull)

    // For each of them, check if they are valid against the form schema. We use a reduce function here to
    // modify the workflowValidation object that the backend returned.
    const ringOrderValidation: Validation = ringOrderIdsToValidate.reduce<Validation>(
      (validation, ringOrderId) => {
        const ringOrder = this.root.ringOrder.getById(ringOrderId)

        const parse = RingOrderFormSchema.safeParse(ringOrder)

        if (parse.success) {
          return validation
        }

        const workflowStepId = Object.values(definition.workflowSteps).find(
          (step) => step.configuration?.some((config) => config.value === ringOrderId),
        )?.id

        if (!workflowStepId) {
          return validation
        }

        validation.valid = false
        validation.errors.push({
          path: `workflowSteps/${workflowStepId}/configuration`,
          message: 'Ring order configuration not valid',
        })

        return validation
      },
      workflowValidation,
    )

    return ringOrderValidation
  }

  discardWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)

    if (!definition) {
      return
    }

    const rawDefinition = this.definitionsBeforeModificationsById.get(definitionId)

    if (!rawDefinition) {
      return
    }

    this.definitionsCollection.put(new WorkflowDefinitionModel(rawDefinition))
    this.definitionsBeforeModificationsById.delete(definitionId)
    this.historyById.delete(definitionId)

    const discardRingOrderConfigChanges = (
      config: WorkflowSharedTypes.WorkflowConfigurationValue,
    ) => {
      if (config.variableKey === 'WVringOrderId' && typeof config.value === 'string') {
        this.root.ringOrder.discardRingOrderChanges(config.value)
      }
    }

    rawDefinition.configuration?.forEach((config) =>
      discardRingOrderConfigChanges(config),
    )
    Object.values(rawDefinition.workflowSteps).forEach((step) => {
      step.configuration?.forEach((config) => discardRingOrderConfigChanges(config))
    })
  }

  fetchStepDefinitions() {
    this.root.transport.workflow.stepDefinitions.list(1000).then((response) => {
      this.stepDefinitionsCollection.putBulk(
        response.data.map((item) => new WorkflowStepDefinitionModel(item)),
      )
    })
  }

  fetchTriggerDefinitions() {
    this.root.transport.workflow.triggerDefinitions.list(1000).then((response) => {
      this.triggerDefinitionsCollection.putBulk(
        response.data.map((item) => new WorkflowTriggerDefinitionModel(item)),
      )
    })
  }

  async addNode({
    definitionId,
    sourceId,
    targetId,
    stepDefinitionId,
    idToReUse,
    incomingBranchKey,
    configuration,
    goToTargetId,
  }: {
    definitionId: string
    sourceId: string
    targetId: string | null
    stepDefinitionId: StepDefinitionId
    idToReUse?: string
    incomingBranchKey?: string
    configuration?: WorkflowDefinitionModel['configuration']
    goToTargetId?: string | null
  }) {
    // TODO this is a placeholder implementation
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const id = idToReUse ?? generateStepId()

    const sourceStep = definition.workflowSteps[sourceId]

    // If the source is a step, we need to update the branch that points to the target.
    // If the source is a trigger, we don't need to do anything since there are no branches.
    // If we are providing an idToReUse, it means the branches already exist
    if (sourceStep && !idToReUse) {
      const branchToUpdateIndex =
        sourceStep.branches?.findIndex((branch) => branch.nextStepId === targetId) ?? -1
      const branchToUpdate = sourceStep.branches
        ? sourceStep.branches[branchToUpdateIndex]
        : null

      if (branchToUpdate) {
        branchToUpdate.key = incomingBranchKey ?? branchToUpdate.key
        branchToUpdate.nextStepId = id
      } else {
        sourceStep.branches = [
          ...(sourceStep.branches ?? []),
          {
            // TODO placeholder
            key: incomingBranchKey
              ? incomingBranchKey
              : sourceStep.definitionId === 'WSDringUsers'
              ? 'WBcallMissed'
              : 'WB',
            nextStepId: id,
          },
        ]
      }
    }

    const newVoicemailStep: WorkflowDefinitionModel['workflowSteps'][string] = {
      id: generateStepId(),
      definitionId: 'WSDvoicemail',
      branches: [],
      configuration: this.getVoicemailConfiguration(
        sourceStep?.branches?.find((branch) => branch.nextStepId === id)?.key ===
          'WBduringHours'
          ? {
              phoneNumberId: definition.entityId,
              type: 'duringHours',
            }
          : {
              phoneNumberId: definition.entityId,
              type: 'afterHours',
            },
      ),
    }

    const newVoicemailStepAfterRingUsers: WorkflowDefinitionModel['workflowSteps'][string] =
      {
        ...newVoicemailStep,
        id: generateStepId(),
      }

    const newRingUsersStep: WorkflowDefinitionModel['workflowSteps'][string] = {
      id: generateStepId(),
      definitionId: 'WSDringUsers',
      branches:
        stepDefinitionId === 'WSDbusinessHours'
          ? [
              {
                key: 'WBcallMissed',
                nextStepId: newVoicemailStepAfterRingUsers.id,
              },
            ]
          : [],
      configuration: this.getConfiguration(
        'WSDringUsers',
        definition.entityId,
        sourceId,
        definitionId,
      ),
    }

    const newGoToStep: WorkflowDefinitionModel['workflowSteps'][string] | null =
      goToTargetId
        ? {
            id: generateStepId(),
            definitionId: 'WSDgoTo',
            branches: [
              {
                key: 'WB',
                nextStepId: goToTargetId,
              },
            ],
          }
        : null

    const newStep: WorkflowDefinitionModel['workflowSteps'][string] = {
      id,
      definitionId: stepDefinitionId,
      configuration: configuration
        ? configuration
        : incomingBranchKey === 'WBduringHours' && stepDefinitionId === 'WSDvoicemail'
        ? this.getVoicemailConfiguration({
            phoneNumberId: definition.entityId,
            type: 'duringHours',
          })
        : incomingBranchKey === 'WBafterHours' && stepDefinitionId === 'WSDvoicemail'
        ? this.getVoicemailConfiguration({
            phoneNumberId: definition.entityId,
            type: 'afterHours',
          })
        : this.getConfiguration(
            stepDefinitionId,
            definition.entityId,
            sourceId,
            definitionId,
          ),
      branches:
        stepDefinitionId === 'WSDbusinessHours'
          ? [
              {
                key: 'WBduringHours',
                nextStepId: targetId ?? newRingUsersStep.id,
              },
              {
                key: 'WBafterHours',
                nextStepId: newVoicemailStep.id,
              },
            ]
          : stepDefinitionId === 'WSDphoneMenu'
          ? [
              ...(targetId
                ? [
                    {
                      key: 'WBoption1',
                      nextStepId: targetId,
                    },
                  ]
                : []),
              {
                key: 'WBdefaultOption',
                nextStepId: newVoicemailStep.id,
              },
            ]
          : stepDefinitionId === 'WSDplayAudioAndContinue' && newGoToStep
          ? [{ key: 'WB', nextStepId: newGoToStep.id }]
          : stepDefinitionId === 'WSDringUsers'
          ? [
              {
                key: 'WBcallMissed',
                nextStepId: targetId ?? newVoicemailStep.id,
              },
            ]
          : !targetId
          ? []
          : [
              {
                key: 'WB',
                nextStepId: targetId,
              },
            ],
    }

    const workflowSteps = {
      ...definition.workflowSteps,
      ...(sourceStep ? { [sourceStep.id]: sourceStep } : {}),
      [newStep.id]: newStep,
      ...(stepDefinitionId === 'WSDbusinessHours' ||
      stepDefinitionId === 'WSDphoneMenu' ||
      (stepDefinitionId === 'WSDringUsers' && !targetId)
        ? { [newVoicemailStep.id]: newVoicemailStep }
        : {}),
      ...(stepDefinitionId === 'WSDbusinessHours' && !targetId
        ? {
            [newRingUsersStep.id]: newRingUsersStep,
            [newVoicemailStepAfterRingUsers.id]: newVoicemailStepAfterRingUsers,
          }
        : {}),
      ...(stepDefinitionId === 'WSDplayAudioAndContinue' && newGoToStep
        ? { [newGoToStep.id]: newGoToStep }
        : {}),
    }

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      // An undefined sourceStep means this new node was placed
      // immediately after the trigger and is now technically the
      // first step in the definition (because triggers aren't part
      // of definition steps). In this case, update the definition
      // initialStepId to this new step's id.
      // Otherwise, preserve the original value.
      initialStepId: sourceStep ? definition.initialStepId : newStep.id,
      workflowSteps,
    })

    this.updateLocalWorkflow(updatedWorkflowDefinition)
  }

  removeNode(
    definitionId: string,
    nodeId: string,
    removeBranchesPointingToNode = true,
    skipAddingToHistory = false,
  ) {
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const workflowSteps = { ...definition.workflowSteps }

    if (workflowSteps[nodeId].definitionId !== 'WSDgoTo') {
      this.getAllChildrenSteps(definition.id, workflowSteps[nodeId]).forEach(
        (step) => delete workflowSteps[step.id],
      )
    }
    delete workflowSteps[nodeId]

    if (removeBranchesPointingToNode) {
      Object.values(workflowSteps).forEach((step) => {
        step.branches = step.branches?.filter((branch) => branch.nextStepId !== nodeId)
      })
    }

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      workflowSteps,
    })

    this.updateLocalWorkflow(updatedWorkflowDefinition, skipAddingToHistory)
  }

  replaceNode(
    definitionId: string,
    stepId: string,
    newStepDefinitionId: StepDefinitionId,
  ) {
    const source = this.getSources(definitionId, stepId).filter(
      (source) => source.definitionId !== 'WSDgoTo',
    )[0]

    const incomingBranchKey = source?.branches?.find(
      (branch) => branch.nextStepId === stepId,
    )?.key

    this.removeNode(definitionId, stepId, false, true)
    this.addNode({
      idToReUse: stepId,
      definitionId,
      sourceId: source?.id,
      // TODO(WFA-431): this will change in the future when the user can freely add goTo blocks.
      // For now, we hardcode the destination since they are only used in the phone menu.
      targetId: newStepDefinitionId === 'WSDgoTo' ? source.id : null,
      stepDefinitionId: newStepDefinitionId,
      incomingBranchKey,
      goToTargetId:
        // TODO(WFA-432): this will change in the future. For now, we hardcode the goToTargetId since
        // playAudioAndContinue blocks can only be added from the phone menu and have a custom behavior.
        newStepDefinitionId === 'WSDplayAudioAndContinue'
          ? source.branches?.find((branch) => branch.key === 'WBdefaultOption')
              ?.nextStepId
          : null,
    })
  }

  updateNode({
    definitionId,
    stepId,
    update,
  }: {
    definitionId: string
    stepId: string
    update: Partial<WorkflowDefinitionModel['workflowSteps'][string]>
  }) {
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const workflowSteps = { ...definition.workflowSteps }

    const updatedStep = {
      ...workflowSteps[stepId],
      ...update,
    }

    workflowSteps[stepId] = updatedStep

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      workflowSteps,
    })

    this.updateLocalWorkflow(updatedWorkflowDefinition)
  }

  hasChanges(definitionId: string) {
    return this.definitionsBeforeModificationsById.has(definitionId)
  }

  isStepDuringBusinessHours(definitionId: string, stepId: string): boolean {
    const previousSteps = this.getPreviousSteps(definitionId, stepId)
    const isDuringBusinessHours = previousSteps.some(
      (node) =>
        this.getDefinitionById(definitionId)?.workflowSteps[node.id].branches?.some(
          (branch) =>
            branch.key === 'WBduringHours' &&
            (previousSteps.some((n) => n.id === branch.nextStepId) ||
              stepId === branch.nextStepId),
        ),
    )

    return isDuringBusinessHours
  }

  private getConfiguration(
    stepDefinitionId: StepDefinitionId,
    phoneNumberId: string,
    workflowStepId: string,
    definitionId: string,
  ): WorkflowDefinitionModel['configuration'] {
    if (stepDefinitionId === 'WSDringUsers') {
      const newRingOrder = this.root.ringOrder.addRingOrderToCollection(phoneNumberId)

      if (!newRingOrder) {
        throw new Error('Failed to create ring order')
      }

      return [
        {
          variableKey: 'WVringOrderId',
          value: newRingOrder.id,
        },
      ]
    }

    if (stepDefinitionId === 'WSDphoneMenu') {
      return [
        {
          variableKey: 'WVphoneMenuDefaultDestination',
          value: this.getVoicemailConfiguration({
            definitionId,
            workflowStepId,
            phoneNumberId,
          })[0].value,
        },
      ]
    }

    if (stepDefinitionId === 'WSDvoicemail') {
      return this.getVoicemailConfiguration({
        definitionId,
        workflowStepId,
        phoneNumberId,
      })
    }

    return []
  }

  private getVoicemailConfiguration(
    args: {
      phoneNumberId: string
    } & (
      | {
          definitionId: string
          workflowStepId: string
        }
      | { type: 'afterHours' | 'duringHours' }
    ),
  ) {
    const phoneNumber = this.root.organization.phoneNumber.collection.get(
      args.phoneNumberId,
    )

    if ('definitionId' in args && 'workflowStepId' in args) {
      return [
        {
          variableKey: 'WVvoicemailUrl',
          value:
            (this.isStepDuringBusinessHours(args.definitionId, args.workflowStepId)
              ? phoneNumber?.voicemailUrl
              : phoneNumber?.awayVoicemailUrl) ?? '',
        },
      ]
    }

    return [
      {
        variableKey: 'WVvoicemailUrl',
        value:
          (args.type === 'duringHours'
            ? phoneNumber?.voicemailUrl
            : phoneNumber?.awayVoicemailUrl) ?? '',
      },
    ]
  }

  async fetchDefinitionsForEntityIdsIfNeeded(
    entityIds: string[],
    localDefinitionsForEntityIds: WorkflowDefinitionModel[],
  ) {
    const entityIdsToFetch = entityIds.filter(
      (entityId) => !this.fetchedDefinitionsEntityIds.has(entityId),
    )
    if (entityIdsToFetch.length === 0) {
      return
    }

    // We want to fetch the remote definitions associated with the entity ids
    // at least once per session to make sure we have the latest data
    const remoteDefinitionsForEntityIds =
      await this.fetchDefinitionsForEntityIds(entityIdsToFetch)

    entityIdsToFetch.forEach((entityId) => {
      runInAction(() => {
        this.fetchedDefinitionsEntityIds.add(entityId)
      })
    })

    // Remove the local entities that have been deleted in the remote
    localDefinitionsForEntityIds.forEach((localDefinition) => {
      const remoteDefinition = remoteDefinitionsForEntityIds.find(
        (remoteDefinition) => remoteDefinition.id === localDefinition.id,
      )

      if (!remoteDefinition) {
        this.definitionsCollection.delete(localDefinition.id)
      }
    })

    remoteDefinitionsForEntityIds.forEach((remoteDefinition) => {
      this.handleRemoteDefinition(remoteDefinition)
    })
  }

  private async fetchStepDefinitionFromRemoteIfNeeded(
    stepDefinitionId: string,
    localStepDefinition: WorkflowStepDefinitionModel | null,
  ) {
    // We want to fetch the remote step definition at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedStepDefinitionIds.has(stepDefinitionId)
    if (alreadyFetched) {
      return
    }

    const remoteStepDefinition = await this.fetchStepDefinitionById(stepDefinitionId)
    runInAction(() => {
      this.fetchedStepDefinitionIds.add(stepDefinitionId)
    })

    if (localStepDefinition && !remoteStepDefinition) {
      // If the remote step definition has been deleted, delete the local one too
      this.stepDefinitionsCollection.delete(localStepDefinition.id)
    } else if (localStepDefinition && remoteStepDefinition) {
      // If both local and remote exist, update local step definition with remote data
      localStepDefinition.deserialize(remoteStepDefinition)
    } else if (!localStepDefinition && remoteStepDefinition) {
      // If the step definition exists only on remote, add it to the local collection
      this.stepDefinitionsCollection.put(
        new WorkflowStepDefinitionModel(remoteStepDefinition),
      )
    }
  }

  private async fetchTriggerDefinitionFromRemoteIfNeeded(
    triggerDefinitionId: string,
    localTriggerDefinition: WorkflowTriggerDefinitionModel | null,
  ) {
    // We want to fetch the remote trigger definition at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedTriggerDefinitionIds.has(triggerDefinitionId)
    if (alreadyFetched) {
      return
    }

    const remoteTriggerDefinition =
      await this.fetchTriggerDefinitionById(triggerDefinitionId)
    runInAction(() => {
      this.fetchedTriggerDefinitionIds.add(triggerDefinitionId)
    })

    if (localTriggerDefinition && !remoteTriggerDefinition) {
      // If the remote trigger definition has been deleted, delete the local one too
      this.triggerDefinitionsCollection.delete(localTriggerDefinition.id)
    } else if (localTriggerDefinition && remoteTriggerDefinition) {
      // If both local and remote exist, update local trigger definition with remote data
      localTriggerDefinition.deserialize(remoteTriggerDefinition)
    } else if (!localTriggerDefinition && remoteTriggerDefinition) {
      // If the trigger definition exists only on remote, add it to the local collection
      this.triggerDefinitionsCollection.put(
        new WorkflowTriggerDefinitionModel(remoteTriggerDefinition),
      )
    }
  }

  private async fetchDefinitionsForEntityIds(entityIds: string[]) {
    const response = await this.root.transport.workflow.definitions.list({
      orgId: this.root.organization.getCurrentOrganization().id,
      userId: this.root.user.getCurrentUser().id,
      maxResults: 50,
      entityIds,
    })

    return response.data
  }

  private async fetchStepDefinitionById(id: string) {
    const response = await this.root.transport.workflow.stepDefinitions.get(id)

    if (!response) {
      return
    }

    return response
  }

  private async fetchTriggerDefinitionById(id: string) {
    const response = await this.root.transport.workflow.triggerDefinitions.get(id)

    if (!response) {
      return
    }

    return response
  }

  private updateOrCreateRingOrders(definition: WorkflowDefinitionModel) {
    return Promise.all(
      Object.values(definition.workflowSteps).map((step) => {
        if (step.definitionId !== 'WSDringUsers') {
          return
        }

        const ringOrderId = step.configuration?.find(
          (c) => c.variableKey === 'WVringOrderId',
        )?.value

        if (typeof ringOrderId !== 'string') {
          return
        }

        const ringOrder = this.root.ringOrder.getById(ringOrderId)

        if (!ringOrder) {
          return
        }

        return this.root.ringOrder.update(ringOrder)
      }),
    )
  }

  private deleteRemovedRingOrdersFromRemote(
    definition: WorkflowDefinitionModel,
    definitionBeforeModifications: RawWorkflowDefinition,
  ) {
    Object.values(definitionBeforeModifications.workflowSteps)
      .filter((step) => step.definitionId === 'WSDringUsers')
      .forEach((step) => {
        if (definition.workflowSteps[step.id]) {
          return
        }

        const ringOrderId = step.configuration?.find(
          (c) => c.variableKey === 'WVringOrderId',
        )?.value

        if (typeof ringOrderId !== 'string') {
          return
        }

        this.root.ringOrder.delete(ringOrderId)
      })
  }

  private handleRemoteDefinition(definition: RawWorkflowDefinition) {
    if (this.definitionsBeforeModificationsById.has(definition.id)) {
      // If the local version has been modified but not published, instead of replacing it with
      // the remote version, we update the pre-modified version with the latest remote data.
      // At this point, the user can either:
      //  - discard (and be back to the latest remote data); or
      //  - publish (and replace the remote data with the modified one)
      this.definitionsBeforeModificationsById.set(definition.id, definition)

      // However, if the enabled state has changed, then we do need to  update the local version
      // to have the new enabled value.
      const localDefinition = this.definitionsCollection.get(definition.id)
      if (localDefinition && definition.enabled !== localDefinition.enabled) {
        this.definitionsCollection.put(
          new WorkflowDefinitionModel({
            ...localDefinition.serialize(),
            enabled: definition.enabled,
          }),
        )
      }
      return
    }

    this.definitionsCollection.put(new WorkflowDefinitionModel(definition))
  }

  private getAllChildrenSteps(
    definitionId: string,
    workflowStep: WorkflowDefinitionModel['workflowSteps'][string],
  ) {
    const childrenSteps: WorkflowDefinitionModel['workflowSteps'][string][] = []

    workflowStep.branches?.forEach((branch) => {
      const nextStep = this.getWorkflowStepById(definitionId, branch.nextStepId)

      if (!nextStep) {
        return
      }

      childrenSteps.push(nextStep)

      if (nextStep.definitionId === 'WSDgoTo') {
        return
      }

      childrenSteps.push(...this.getAllChildrenSteps(definitionId, nextStep))
    })

    return childrenSteps
  }

  private getSources(
    definitionId: string,
    stepId: string,
  ): WorkflowDefinitionModel['workflowSteps'][string][] {
    const definition = this.definitionsCollection.get(definitionId)

    if (!definition) {
      return []
    }

    return Object.values(definition.workflowSteps).filter(
      (step) => step.branches?.some((branch) => branch.nextStepId === stepId),
    )
  }

  getPreviousSteps(
    definitionId: string,
    startingStepId: string,
  ): WorkflowDefinitionModel['workflowSteps'][string][] {
    const previousSteps: WorkflowDefinitionModel['workflowSteps'][string][] = []

    const populatePreviousSteps = (stepId: string) => {
      this.getSources(definitionId, stepId).forEach((s) => {
        previousSteps.push(s)

        if (s.definitionId === 'WSDgoTo') {
          return
        }

        populatePreviousSteps(s.id)
      })
    }

    populatePreviousSteps(startingStepId)

    return previousSteps
  }

  upsertDefinition(definition: RawWorkflowDefinition) {
    this.definitionsCollection.put(new WorkflowDefinitionModel(definition))
  }

  private load() {
    return Promise.all([
      this.definitionsCollection.performQuery((repo) => repo.all()),
      this.stepDefinitionsCollection.performQuery((repo) => repo.all()),
      this.triggerDefinitionsCollection.performQuery((repo) => repo.all()),
    ])
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'phone-number-update': {
          // We only need to handle the case where a phone number was opted out of the beta.
          // If a phone number was opted in, then the CallFlow component (which will be shown
          // now that incomingCallWorkflowEnabled is true) will take care of fetching the
          // relevant workflow definition data
          if (!data.phoneNumber.settings?.incomingCallWorkflowEnabled) {
            const entityId = data.phoneNumber.id

            const localDefinitionsForEntityId = this.definitionsCollection.list.filter(
              (definition) => definition.entityId === entityId,
            )
            this.definitionsCollection.deleteBulk(localDefinitionsForEntityId)
            this.fetchedDefinitionsEntityIds.delete(entityId)
          }
          break
        }
        case 'workflow-definition-create': {
          this.definitionsCollection.put(new WorkflowDefinitionModel(data.definition))
          break
        }
        case 'workflow-definition-update': {
          this.handleRemoteDefinition(data.definition)
          break
        }
        case 'workflow-definition-delete': {
          // No op for now
          break
        }
      }
    })
  }
}
